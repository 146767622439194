import { Link } from "react-router-dom";



const Subscription = () => {
  return (
    <div className="bg-gradient-to-r from-main-color lg:h-dvh">
      <div className="flex w-full flex-col ">
        <div className="divider">
          <h1 className="font-bold text-2xl">Vyapara Listing pricing</h1>
        </div>
      </div>
      <div className="flex justify-center flex-wrap">
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <h2 className="card-title">1 Year - 1499 &#x20b9; Only/- </h2>
            <p>This plan offers comprehensive features with a display period of one year on our website.</p>
            <div className="card-actions justify-end">
            <Link className="btn bg-main-color" to="/business/register">Register Now</Link>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <h2 className="card-title">3 Years - 4299 &#x20b9; Only/-</h2>
            <p>Enjoy extended visibility with a display period of three years on our website.</p>
            <div className="card-actions justify-end">
            <Link className="btn bg-main-color" to="/business/register">Register Now</Link>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <h2 className="card-title">5 Years (Premium) - 8499 &#x20b9; Only/-</h2>
            <p>Our premium option includes prominent banner ads on the homepage and display throughout the website for five years, ensuring maximum exposure.</p>
            <div className="card-actions justify-end">
              <Link className="btn bg-main-color" to="/business/register">Register Now</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col">
        <div className="divider">
          <h1 className="font-bold text-2xl">Thandh Bendh pricing</h1>
        </div>
      </div>
      <div className="flex justify-center flex-wrap">
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <div className="badge badge-accent text-xl">35 % off </div>
            <h2 className="text-xl font-semibold">Gold Plan : 6 Months - <span class="text-gray-500 font-semibold line-through"> 2000 &#x20b9; </span> 1300 &#x20b9; Only/- </h2>
            <p>6 Months profile will be displayed on the website</p>
            <div className="card-actions justify-end">
            <Link className="btn bg-main-color" to="/matrimony/userprofile">Register Now</Link>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <div className="badge badge-accent text-xl">33 % off </div>
            <h2 className="text-xl font-semibold">Diamond Plan : 12 Months  <span class="text-gray-500 font-semibold line-through"> 3000 &#x20b9; </span> 2000 &#x20b9; Only/- </h2>
            <p>1 year profile will be displayed on the website</p>
            <div className="card-actions justify-end">
            <Link className="btn bg-main-color" to="/matrimony/userprofile">Register Now</Link>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 w-96 shadow-xl m-4">
          <div className="card-body">
            <div className="badge badge-accent text-xl">25 % off </div>
            <h2 className="text-xl font-semibold">Platinum Plan : 18 Months  <span class="text-gray-500 font-semibold line-through"> 4000 &#x20b9; </span> 3000 &#x20b9; Only/- </h2>
            <p>1.5 years displayed on the website</p>
            <div className="card-actions justify-end">
              <Link className="btn bg-main-color" to="/matrimony/userprofile">Register Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
