import { Link } from "react-router-dom";
import Logo from "./assets/logo.png";
import MatrimonyTermsOfUser from "./MatrimonyTermsOfUser";
import BusinessTermsOfUser from "./BusinessTermsOfUser";

const Footer = () => {
  return (

    <footer className="bg-white dark:bg-gray-900">
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg text-black">
            Matrimony Terms Of Use
            

          </h3>
          <MatrimonyTermsOfUser />
          
          {/* <h3 className="font-bold text-lg text-black">
            Business Terms Of Use
          </h3>

          <BusinessTermsOfUser /> */}
    
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
      <dialog id="my_modal5" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg text-black">
            Business Terms and Conditions
          </h3>
          <BusinessTermsOfUser />
          
          
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link href="https://flowbite.com/" className="flex items-center">
              <img src={Logo} className="h-8 me-3" alt="FlowBite Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                KODAGU HEGGADE
              </span>
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact Us
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a
                    href="mailto:kodaguheggade@gmail.com"
                    className="hover:underline"
                  >
                    kodaguheggade@gmail.com
                  </a>
                </li>
                <li>
                  <Link
                    href="https://tailwindcss.com/"
                    className="hover:underline"
                  >
                    +91 9449303760
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Advertise
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link to="/business/register" className="hover:underline ">
                    Business Register
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link href="#" className="hover:underline">
                    Report Abuse
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="#"
                    className="hover:underline"
                    onClick={() =>
                      document.getElementById("my_modal_4").showModal()
                    }
                  >
                    Matrimony Terms &amp; Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    className="hover:underline"
                    onClick={() =>
                      document.getElementById("my_modal5").showModal()
                    }
                  >
                    Business Terms &amp; Cancellation
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <Link href="https://flowbite.com/" className="hover:underline">
              KODAGU HEGGADE
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
