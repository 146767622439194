import React from "react";

const LoadingDetail = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-wrap h-[80vh] w-2/3 items-center justify-center">
        <div className="flex w-full flex-col gap-4 m-3">
          <div className="skeleton h-32 w-full"></div>
          <div className="skeleton h-8 w-28"></div>
          <div className="skeleton h-4 w-full"></div>
          <div className="skeleton h-4 w-full"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDetail;
