import { Link } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../Footer/assets/logo.png";

const Navbar = () => {
  return (
    <div className="navbar bg-main-color">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex="0" role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex="0"
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 text-[16px]"
          >
            <li className="font-bold">
              <Link to="/">Home</Link>
            </li>
            <li className="font-bold">
              <Link to="/about">About</Link>
            </li>

            <li className="font-bold z-20">
            <details>
              <summary>Businees</summary>
              <ul className="p-2">
                <li className="font-bold">
                  <Link to="/business">Business Listings</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/dashboard">Dashboard</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/register">Register</Link>
                </li>
              </ul>
            </details>
          </li>

            {/* <li className="font-bold">
              <Link to="/matrimony/listusers">Profiles</Link>
            </li> */}
            

            <li className="font-bold">
              <Link to='/event'>Events</Link>
            </li>
            

            <li className="font-bold z-20">
              <details>
                <summary>Matrimony</summary>
                <ul className="p-2">
                  <li className="font-bold">
                    <Link to="/matrimony/listusers">Profiles</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/matrimony/dashboard">Dashboard</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/matrimony/register">Register</Link>
                  </li>
                </ul>
              </details>
            </li>

            <li className="font-bold">
              <Link to="/matrimony/interest">Notifications</Link>
            </li>

            <li className="font-bold">
              <Link to='/subscription'>Pricing</Link>
            </li>
            
            {/* <li className="font-bold">
              <Link>Dashboard</Link>
              <ul className="p-2">
                <li className="font-bold">
                  <Link>Vyapara</Link>
                </li>
                <li className="font-bold">
                  <Link>Thandh Bendh</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <img src={Logo} className="h-12" alt="Flowbite Logo" />
        <span className="self-center text-4xl font-semibold whitespace-nowra">
          KODAGU HEGGADE
        </span>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 text-2xl">
          <li className="font-bold">
            <Link to="/">Home</Link>
          </li>
          
          
          <li className="font-bold">
            <Link to="/about">About</Link>
          </li>

          <li className="font-bold z-20">
            <details>
              <summary>Businees</summary>
              <ul className="p-2">
                <li className="font-bold">
                  <Link to="/business">Business Listings</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/dashboard">Dashboard</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/register">Register</Link>
                </li>
              </ul>
            </details>
          </li>
          

          <li className="font-bold">
            <Link to="/event">Events</Link>
          </li>
          {/* <li className="font-bold">
            <details>
              <summary>Dashboard</summary>
              <ul className="p-2 z-10">
                <li className="font-bold">
                  <Link to="/businesss/dashboard">Vyapara</Link>
                </li>>
                <li className="font-bold">
                  <Link>Thandh Bendh</Link>
                </li>
              </ul>
            </details>
          </li> */}
          
          <li className="font-bold z-20">
            <details>
              <summary>Matrimony</summary>
              <ul className="p-2">
                <li className="font-bold">
                  <Link to="/matrimony/listusers">Profiles</Link>
                </li>
                <li className="font-bold">
                  <Link to="/matrimony/dashboard">Dashboard</Link>
                </li>
                <li className="font-bold">
                  <Link to="/matrimony/register">Register</Link>
                </li>
              </ul>
            </details>
          </li>

          <li className="font-bold">
          <Link to="/matrimony/interest">Notifications</Link>
          </li>

          <li className="font-bold">
            <Link to="/subscription">Pricing</Link>
          </li>
          
        </ul>
        
      </div>
      <div className="navbar-end">
        <Link className="btn" to="/matrimony/login">
          Login
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
