import React from "react";
import { useState, useEffect } from "react";
import {DOMAIN_NAME, BUSINESS_DASHBOARD_GET, BUSINESS_DASHBOARD_UPDATE } from "../../Constant";

const BusinessDashboard = () => {
  const [businessImages, setBusinessImages] = useState("");
  const [verificationImage, setVerificationImages] = useState("");
  const [fullName, setFullName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [typeOfBusiness, setTypeOfBusiness] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [status, setStatus] = useState("");

  const populateBusinessInfo = (user) => {
      
    setFullName(user?.user?.full_name);
    setEmail(user?.user?.email);
    setMobileNumber(user?.user?.mobile_number);
    setBusinessName(user?.business_name);
    setTypeOfBusiness(user?.type_of_buisness);
    setBusinessAddress(user?.business_address);
    setGstNumber(user?.gst_number);
  };


  useEffect(() => {
    try {
      const fetchBusinessProfile = async () => {
          try {
            const token = localStorage.getItem("token");
            const response = await fetch(DOMAIN_NAME + BUSINESS_DASHBOARD_GET , {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`, // Adding the auth token to headers
              }});

            if (response.ok) {
              const businessUserData = await response.json();
              // setProposals(matrimonyUserData?.data);
              populateBusinessInfo(businessUserData?.data)
              
            } else {
              if (!response.ok) {
                  alert('Failed to load')
              }          
            }                                  
          } catch (err) {
            console.log(err)
          }
        };
      fetchBusinessProfile();
    
  } catch (e) {
      console.log(e)
  }
  }, [])


  const clearState = () => {
    setBusinessImages("");
    setVerificationImages("");
    setFullName("");
    setBusinessName("");
    setTypeOfBusiness("");
    setMobileNumber("");
    setEmail("");
    setBusinessAddress("");
    setGstNumber("");
    setStatus("");
  };

  const handleBusinessFileUpload = (e) => {
    setBusinessImages(e.target.files[0]);
  };

  const handleVerificationImageUpload = (e) => {
    setVerificationImages(e.target.files[0]);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("full_name", fullName);
      formData.append("mobile_number", mobileNumber);
      formData.append("type_of_buisness", typeOfBusiness);
      formData.append("business_name", businessName);
      formData.append("email", email);
      formData.append("business_address", businessAddress);
      formData.append("verification_image", verificationImage);
      formData.append("shop_image", businessImages);
      formData.append("gst_number", gstNumber);

      const token = localStorage.getItem("token");
      const response = await fetch(DOMAIN_NAME + BUSINESS_DASHBOARD_UPDATE, {
        method: "PUT",
        body: formData,
        headers: {
          "Authorization": `Bearer ${token}`// Adding the auth token to headers
        }
      });
      const data = await response.json();

      if (response?.ok) {
    
        alert("updated data sucessfully")
        // clearState();
      }

      if (!response.ok) {
        alert("Couldn't able to update data");
      }

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="lg:m-6 md:m-6 sm:m-6 min-h-svh">
        <form onSubmit={handleRegister}>
          <p className="text-green-700 font-medium text-2xl">{status}</p>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="full_name"
                className="block mb-2 text-sm font-medium  "
              >
                Name (not editable)
              </label>
              <input
                type="text"
                id="full_name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                readOnly={true}
              />
            </div>

            <div>
              <label
                htmlFor="Businees Name"
                className="block mb-2 text-sm font-medium  "
              >
                Businees Name 
              </label>
              <input
                type="text"
                id="Businees Name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                
              />
            </div>
            <div>
              <label
                htmlFor="Type of Business"
                className="block mb-2 text-sm font-medium  "
              >
                Type of Business 
              </label>
              <input
                type="text"
                id="Type of Business"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={typeOfBusiness}
                onChange={(e) => setTypeOfBusiness(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Mobile Number"
                className="block mb-2 text-sm font-medium  "
              >
                Mobile Number (not editable)
              </label>
              <input
                type="number"
                id="Mobile Number"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                readOnly={true}
              />
            </div>
            <div>
              <label
                htmlFor="Email"
                className="block mb-2 text-sm font-medium  "
              >
                Email (not editable)
              </label>
              <input
                type="text"
                id="Email"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={true}
              />
            </div>

            <div>
              <label
                htmlFor="Business Address"
                className="block mb-2 text-sm font-medium  "
              >
                Business Address 
              </label>
              <input
                type="text"
                id="Business Address"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="GST Number"
                className="block mb-2 text-sm font-medium  "
              >
                GST Number (not editable)
              </label>
              <input
                type="text"
                id="GST Number"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                readOnly={true}
              />
            </div>

            

            {/* <div>
              <label
                htmlFor="business photos"
                className="block mb-2 text-sm font-medium  "
              >
                Vypara photos 
              </label>
              <input
                type="file"
                id="business photos"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleBusinessFileUpload}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              /> */}
              {/* <div className="flex">
              {businessImages.map((file, index) =>
                file instanceof File ? (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className="block w-24 h-24 object-cover"
                  />
                ) : (
                  <p key={index}>Invalid file</p>
                )
              )}
            </div> */}
            {/* </div> */}
          </div>

         
          <button type="submit" className="btn bg-main-color btn-ghost">
            Update
          </button>
        </form>
      </div>
    </>
  );
};

export default BusinessDashboard;
