export const DOMAIN_NAME = 'https://kodaguheggade.in'

export const SIGN_UP = '/users/v2/register'

export const GET_OTP = '/users/v2/login'

export const POST_OTP = '/users/v1/login'

export const BUSINESS_REGISTER = '/users/v1/business/register'

export const LIST_BUSINESS = '/business/v1/list_business'

export const LIST_BUSINESS_V2 = '/business/v2/list_business'

export const BUSINESS_DETAIL = '/business/v1/business_detail?business_id='

export const LIST_USERSV1 = '/matrimony/v1/list_users'

export const MATRIMONY_REGISTER = '/users/v1/matrimony/register'

export const MATRIMONY_USER_DETAIL = '/matrimony/v1/user_details?user='

export const MATRIMONY_SHOW_INTEREST = '/matrimony/v1/show_interest'

export const REJECT_PROPOSAL = '/matrimony/v1/reject_proposal/'

export const ACCEPT_PROPOSAL = '/matrimony/v1/accept_proposal/'

export const MATRIMONY_DASHBOARD_GET = '/matrimony/v1/dashboard/profile'

export const MATRIMONY_DASHBOARD_UPDATE = '/matrimony/v1/dashboard/profile'

export const BUSINESS_DASHBOARD_GET = '/business/v1/dashboard/business'

export const BUSINESS_DASHBOARD_UPDATE = '/business/v1/dashboard/business'

