import React, { useState, useEffect } from "react";
import BannerOneImage from './assets/banner1.jpg';
import BannerTwoImage from './assets/banner2.png';
import BannerThreeImage from './assets/banner3.jpg';
import BannerFourImage from './assets/banner4.jpg';
import BannerSixImage from './assets/banner6.jpeg';
import BannerSevenImage from './assets/banner7.jpg';
import BannerEightImage from './assets/banner8.jpg';




const Carousel = () => {
  return (
    <div className="carousel w-full h-[50vh]">
      <div id="slide1" className="carousel-item relative w-full">
        <img
          src={BannerSevenImage}
          className="w-full object-cover"
        />
        <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
          <a href="#slide4" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide2" className="btn btn-circle">
            ❯
          </a>
        </div>
      </div>
      <div id="slide2" className="carousel-item relative w-full">
        <img
          src={BannerTwoImage}
          className="w-full object-cover"
        />
        <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
          <a href="#slide1" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide3" className="btn btn-circle">
            ❯
          </a>
        </div>
      </div>
      <div id="slide3" className="carousel-item relative w-full">
        <img
          src={BannerThreeImage}
          className="w-full object-cover"
        />
        <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
          <a href="#slide2" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide4" className="btn btn-circle">
            ❯
          </a>
        </div>
      </div>
      <div id="slide4" className="carousel-item relative w-full">
        <img
          src={BannerFourImage}
          className="w-full object-cover"
        />
        <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
          <a href="#slide3" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide1" className="btn btn-circle">
            ❯
          </a>
        </div>
      </div>
    </div>
  );
};

export default Carousel;