import { useState } from "react";
import { DOMAIN_NAME, MATRIMONY_REGISTER } from "../../Constant";
import MatrimonyTermsOfUser from "../Footer/MatrimonyTermsOfUser";

const MatrimonyRegister = () => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherFamilyName, setMotherFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [residentialAddress, setResidentialAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [mariatlStatus, setMartialStatus] = useState("");
  const [instagramId, setInstgramId] = useState("");
  const [facebook, setFacebook] = useState("");
  const [Linkedin, setLinkedin] = useState("");
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [gana, setGana] = useState("");
  const [rashi, setRashi] = useState("");
  const [nakshatra, setNakshatra] = useState("");
  const [qualification, setQualifiction] = useState("");
  const [profession, setProfession] = useState("");
  const [totalYearOfExperience, setTotalYearOfExperience] = useState("");
  const [currentCompany, setCurrentCompnay] = useState("");
  const [parentContactDetails, setParentContactDetails] = useState("");
  const [siblings, setSiblings] = useState("");
  const [verificationImage, setVerificationImage] = useState("");
  const [recentPhoto1, setRecentPhoto1] = useState("");
  const [recentPhoto2, setRecentPhoto2] = useState("");
  const [recentPhoto3, setRecentPhoto3] = useState("");
  const [recentPhoto4, setRecentPhoto4] = useState("");
  const [recentPhoto5, setRecentPhoto5] = useState("");
  const [successOrFailureMessage, setsuccessOrFailureMessage] = useState("")

  const clearState = () => {
    setFullName("");
    setGender("");
    setDateOfBirth("");
    setFatherName("");
    setMotherName("");
    setMotherFamilyName("");
    setEmail("");
    setMobileNumber("");
    setFamilyName("");
    setResidentialAddress("");
    setPermanentAddress("");
    setMartialStatus("");
    setInstgramId("");
    setFacebook("");
    setLinkedin("");
    setTimeOfBirth("");
    setPlaceOfBirth("");
    setGana("");
    setRashi("");
    setNakshatra("");
    setQualifiction("");
    setProfession("");
    setTotalYearOfExperience("");
    setCurrentCompnay("");
    setParentContactDetails("");
    setSiblings("");
    setVerificationImage("");
    setRecentPhoto1("");
    setRecentPhoto2("");
    setRecentPhoto3("");
    setRecentPhoto4("");
    setRecentPhoto5("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("full_name", fullName);
      formData.append("gender", gender);
      formData.append("date_of_birth", dateOfBirth);
      formData.append("father_name", fatherName);
      formData.append("mother_name", motherName);
      formData.append("mother_family_name", motherFamilyName);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("family_name", familyName);
      formData.append("permanent_address", permanentAddress);
      formData.append("residential_address", residentialAddress);
      formData.append("marital_status", mariatlStatus);
      formData.append("instagram_id", instagramId);
      formData.append("facebook_id", facebook);
      formData.append("linkedin", Linkedin);
      formData.append("time_of_birth", timeOfBirth);
      formData.append("place_of_birth", placeOfBirth);
      formData.append("gana", gana);
      formData.append("rashi", rashi);
      formData.append("nakshatra", nakshatra);
      formData.append("highest_qualification", qualification);
      formData.append("profession", profession);
      formData.append("total_year_of_experience", totalYearOfExperience);
      formData.append("current_company", currentCompany);
      formData.append("parent_contact_details", parentContactDetails);
      formData.append("siblings", siblings);
      formData.append("verification_image", verificationImage);
      formData.append("user_image_1", recentPhoto1);
      formData.append("user_image2", recentPhoto2);
      formData.append("user_image3", recentPhoto3);
      formData.append("user_image4", recentPhoto4);
      formData.append("user_image5", recentPhoto5);

      const response = await fetch(DOMAIN_NAME + MATRIMONY_REGISTER, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      console.log(data);
      if (response?.ok) {
        setsuccessOrFailureMessage(
          "You have registered successfully and you can login after verification"
        );
        clearState();
      }

      if (!response.ok) {
        setsuccessOrFailureMessage("Registration failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <div className="p-[2%] flex justify-evenly flex-wrap h-[70vh] items-center">
        <h1 className="font-extrabold text-2xl">Coming soon</h1>
      </div> */}
      <div className="lg:m-6 md:m-6 sm:m-6">
        <p className="text-green-700">{successOrFailureMessage}</p>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div className="">
              <h1 className="text-2xl font-medium">Personel details</h1>
              <hr className=""></hr>
            </div>
            <div></div>
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium  "
              >
                Full Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="first_name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Gender"
                className="block mb-2 text-sm font-medium  "
              >
                Gender <span className="text-main-color">*</span>
              </label>
              <select
                id="options"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">Select option</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium  "
              >
                Date of Birth <span className="text-main-color">*</span>
              </label>
              <input
                type="date"
                id="company"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Flowbite"
                required
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Father Name"
                className="block mb-2 text-sm font-medium  "
              >
                Father Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Father Name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={fatherName}
                onChange={(e) => setFatherName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Mother Name"
                className="block mb-2 text-sm font-medium  "
              >
                Mother Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Mother Name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={motherName}
                onChange={(e) => setMotherName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Mother's Family Name"
                className="block mb-2 text-sm font-medium  "
              >
                Mother's Family Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Mother's Family Name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={motherFamilyName}
                onChange={(e) => setMotherFamilyName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Email"
                className="block mb-2 text-sm font-medium  "
              >
                Email <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Email"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Mobile Number"
                className="block mb-2 text-sm font-medium  "
              >
                Mobile Number <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Mobile Number"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="family name"
                className="block mb-2 text-sm font-medium  "
              >
                Family Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="family name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Address"
                className="block mb-2 text-sm font-medium  "
              >
                Permanent Address <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Address"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={permanentAddress}
                onChange={(e) => setPermanentAddress(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Present Address"
                className="block mb-2 text-sm font-medium  "
              >
                Residential Address <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Present Address"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={residentialAddress}
                onChange={(e) => setResidentialAddress(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Marital status"
                className="block mb-2 text-sm font-medium  "
              >
                Marital status <span className="text-main-color">*</span>
              </label>
              <select
                id="options"
                value={mariatlStatus}
                onChange={(e) => setMartialStatus(e.target.value)}
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">Select option</option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="Instgram Id"
                className="block mb-2 text-sm font-medium  "
              >
                Instgram Id <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Instgram Id"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={instagramId}
                onChange={(e) => setInstgramId(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Fackbook"
                className="block mb-2 text-sm font-medium  "
              >
                Fackbook <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Fackbook"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Linkedin"
                className="block mb-2 text-sm font-medium  "
              >
                Linkedin <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Linkedin"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={Linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </div>

            <div></div>
            <div className="">
              <h1 className="text-2xl font-medium">Horoscope details</h1>
              <hr className=""></hr>
            </div>
            <div></div>
            <div>
              <label
                htmlFor="Time of birth"
                className="block mb-2 text-sm font-medium  "
              >
                Time of birth <span className="text-main-color">*</span>
              </label>
              <input
                type="time"
                id="Time of birth"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={timeOfBirth}
                onChange={(e) => setTimeOfBirth(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Place of birth"
                className="block mb-2 text-sm font-medium  "
              >
                Place of birth <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Place of birth"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={placeOfBirth}
                onChange={(e) => setPlaceOfBirth(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Gana"
                className="block mb-2 text-sm font-medium  "
              >
                Gana <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Gana"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={gana}
                onChange={(e) => setGana(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Rashi"
                className="block mb-2 text-sm font-medium  "
              >
                Rashi <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Rashi"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={rashi}
                onChange={(e) => setRashi(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Nakshatra"
                className="block mb-2 text-sm font-medium  "
              >
                Nakshatra <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Nakshatra"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
              />
            </div>
            <div></div>
            <div className="">
              <h1 className="text-2xl font-medium">Education Qualification</h1>
              <hr className=""></hr>
            </div>
            <div></div>
            <div>
              <label
                htmlFor="Highest Qualification"
                className="block mb-2 text-sm font-medium  "
              >
                Highest Qualification <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Highest Qualification"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={qualification}
                onChange={(e) => setQualifiction(e.target.value)}
              />
            </div>
            <div></div>
            <div className="">
              <h1 className="text-2xl font-medium">Proffesional Details</h1>
              <hr className=""></hr>
            </div>
            <div></div>

            <div>
              <label
                htmlFor="Profession"
                className="block mb-2 text-sm font-medium  "
              >
                Profession <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Profession"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Total year of experience"
                className="block mb-2 text-sm font-medium  "
              >
                Total year of experience <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Total year of experience"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={totalYearOfExperience}
                onChange={(e) => setTotalYearOfExperience(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Current Company"
                className="block mb-2 text-sm font-medium  "
              >
                Current Company <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Current Company"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={currentCompany}
                onChange={(e) => setCurrentCompnay(e.target.value)}
              />
            </div>

            <div></div>
            <div className="">
              <h1 className="text-2xl font-medium">Other Details</h1>
              <hr className=""></hr>
            </div>
            <div></div>

            <div>
              <label
                htmlFor="Parent Contact details"
                className="block mb-2 text-sm font-medium  "
              >
                Parent Contact details <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Parent Contact details"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                
                value={parentContactDetails}
                onChange={(e) => setParentContactDetails(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Siblings"
                className="block mb-2 text-sm font-medium  "
              >
                Siblings
              </label>
              <input
                type="text"
                id="Siblings"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={siblings}
                onChange={(e) => setSiblings(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Upload recent photo"
                className="block mb-2 text-sm font-medium  "
              >
                Upload recent photo 1 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Upload recent photo"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setRecentPhoto1(e.target.files[0])}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="Upload recent photo"
                className="block mb-2 text-sm font-medium  "
              > 
                Upload recent photo 2 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Upload recent photo"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setRecentPhoto2(e.target.files[0])}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="Upload recent photo"
                className="block mb-2 text-sm font-medium  "
              >
                Upload recent photo 3 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Upload recent photo"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setRecentPhoto3(e.target.files[0])}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="Upload recent photo"
                className="block mb-2 text-sm font-medium  "
              >
                Upload recent photo 4 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Upload recent photo"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setRecentPhoto4(e.target.files[0])}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="Upload recent photo"
                className="block mb-2 text-sm font-medium  "
              >
                Upload recent photo 5 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Upload recent photo"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setRecentPhoto5(e.target.files[0])}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="Pan / Aadhar / Voter id for verification"
                className="block mb-2 text-sm font-medium  "
              >
                Pan / Aadhar / Voter id for verification <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Pan / Aadhar / Voter id for verification"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={(e) => setVerificationImage(e.target.files[0])}
              />
            </div>
          </div>

          <div className="flex items-start mb-6">
            {/* <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 -700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                required
              />
            </div> */}
            <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium  dark:text-gray-300"
            >
              <span className="text-black">By Registering you're agreeing with </span>
              
              
                <label htmlFor="my_modal_7" className="btn">terms and conditions</label>
                <input type="checkbox" id="my_modal_7" className="modal-toggle" />
                <div className="modal" role="dialog">
                    <div className="modal-box w-full">
                      <h1 className="text-main-color">Matrimony Terms and Conditions</h1>
                      <MatrimonyTermsOfUser />
                    </div>
                    <label className="modal-backdrop" htmlFor="my_modal_7">Close</label>
                </div>
            </label>
          </div>
          <button
            type="submit"
            className="text-white bg-main-color focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-main-color-600 dark:hover:bg-main-color-700 dark:focus:ring-main-color-800"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default MatrimonyRegister;
