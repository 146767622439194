
import { useParams } from 'react-router-dom';
import Loading from '../components/UX/Loading';
import { useEffect, useState } from 'react';
import { DOMAIN_NAME, BUSINESS_DETAIL } from '../Constant';

const useBusinessDetails = (id) => {

  const [businessDetail, setBusinessDetail] = useState([]);

  async function getBusinessDetails(id) {
    const response = await fetch(DOMAIN_NAME + BUSINESS_DETAIL + id);
    const businessData = await response.json();
    setBusinessDetail(businessData)
  }
  
  useEffect( () => {
    getBusinessDetails(id);
  }, [])

  return [businessDetail];
}

export default useBusinessDetails