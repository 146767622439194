import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DOMAIN_NAME, LIST_BUSINESS, LIST_BUSINESS_V2 } from "../../Constant";
import BusinessListCard from "./BusinessListCard";

const Business = () => {
  const [businessList, setBusinessList] = useState("Loading");

  useEffect(() => {
    const fetchBusinessList = async () => {
      const response = await fetch(DOMAIN_NAME + LIST_BUSINESS_V2);
      const data = await response.json();
      console.log(data, "line 13");
      setBusinessList(data?.business_details);
    };
    fetchBusinessList();
  }, []);

  return (
    <>
      <div role="alert" className="alert ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info h-6 w-6 shrink-0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>In order to list your business please register</span>
        <div>
          <Link to="/business/register">
            <button className="btn bg-main-color btn-ghost">Register</button>
          </Link>
        </div>
      </div>

      <div className="p-[2%] flex min-h-screen	 justify-center bg-cover flex-wrap bg-gradient-to-r from-main-color to-red-300">
        {businessList === "Loading" ? (
          <h1>Loading...</h1>
        ) : businessList.length === 0 ? (
          <h1 className="font-bold">No Business Found</h1>
        ) : (
          businessList.map((business, index) => (
            <BusinessListCard
              key={index}
              name={business?.business_name}
              shopImage={business?.shop_image}
              id={business?.id}
            />
          ))
        )}
      </div>
    </>
  );
};

export default Business;
