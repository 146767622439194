import React from "react";
import { DOMAIN_NAME } from "../../Constant";

const BusinessDetailCard = ({ details }) => {
  const { business_name, business_address, shop_image, user, total_views } = details;

  return (
    
    <div className="card lg:card-side bg-base-100 shadow-xl">
      <figure>
        <img
          src={DOMAIN_NAME + shop_image}
          alt="Album"
          className="w-96 h-96 border object-cover"
        />
      </figure>
      <div className="card-body">
        <h2 className="lg:card-title md:text-center">
          {business_name.toUpperCase()}
        </h2>
        <div>
          <p className="mt-1">
            <span className="font-bold">Business Owner Name</span>
          </p>
          <p>{user?.full_name}</p>
          <p className="mt-1">
            <span className="font-bold">Mobile Number : </span>
          </p>
          <p>{user?.mobile_number}</p>
          <p className="mt-1">
            <span className="font-bold">Business Address : </span>
          </p>
          <p>{business_address}</p>
          <p className="mt-1">
            <span className="font-bold">Total impressions : </span>
          </p>
          <p>{total_views}</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetailCard;
