import React from "react";
import BusinessImgage from "./assets/business_image.png";
import { Link } from "react-router-dom";
const RegisterForBusiness = () => {
  return (
    <>
      <div className="flex justify-center p-[4%] bg-slate-50 shadow-xl">
        <div className="w-[80%] card lg:card-side bg-base-100 shadow-xl">
          <figure>
            <img
              className="w-full md:w-96 h-96 object-fill"
              src={BusinessImgage}
              alt="Album"
            />
          </figure>
          <div className="card-body bg-gradient-to-r  from-main-color to-to-color">
            <h2 className="card-title text-5xl">Register your Business for Banner Ads</h2>
            <p className="font-medium text-3xl">5 Years (Premium) - 8499 ₹ Only/-</p>
            <div className="card-actions justify-end">
            <a href="/subscription"><button className="btn bg-main-color">View More</button></a>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterForBusiness;
