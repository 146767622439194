import React from "react";
import { Link } from "react-router-dom";
import { DOMAIN_NAME } from "../../Constant";

const MatrimonyListCard = ({ user }) => {
  const { age, full_name, profession, id, user_image_1 } = user;
  return (
    <>
    <div className="w-4/5">
      <div className="card card-side bg-base-100 shadow-xl mb-3 mt-3">
        <div className="sm:w-1/2 md:w-1/3 h-80">
          <img
            src={DOMAIN_NAME + user_image_1}
            alt="Movie"
            className="w-full h-full "
          />
        </div>
        <div className="card-body">
          <h2 className="card-title">{full_name}</h2>
          <p>Age : {age}</p>
          <div className="card-actions justify-end">
            <Link to={`/matrimony/user/${id}`}>
              <button className="btn ">View Profile</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MatrimonyListCard;
