import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DOMAIN_NAME, LIST_USERSV1 } from "../../Constant";
import MatrimonyUserUnsubscribed from "../MatrimonyUserProfile/MatrimonyUserUnsubscribed";
import LoadingDetail from "../UX/LoadingDetail";
import MatrimonyListCard from "./MatrimonyListCard";


/**
 * 
 * name, age, family name, father's name , mother's name
 */

const MatrimonyListUser = () => {
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const token = localStorage.getItem("token");

    if (!token) {
      setShowLandingPage(true);
      setLoading(false);
    }

    if (token) {
      const fetchUsers = async () => {
        try {
          const response = await fetch(DOMAIN_NAME + LIST_USERSV1, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          console.log(response)
          
          if (!response.ok) {
            setLoading(false);
            setShowLandingPage(true);
          }

          if (response.ok) {
            const json = await response.json();
            setUsersData(json?.data);
            setShowLandingPage(false);
            setLoading(false)
          }

        } catch {}
      };
      fetchUsers();
    }
  }, []);

  return loading ? (
    <LoadingDetail />
  ) : showLandingPage ? (
    <MatrimonyUserUnsubscribed />
  ) : (
    <div className="flex justify-center flex-wrap">
      {
        usersData.map((user, index) => (
        <MatrimonyListCard key={index} user={user}/>
      ))
      }
      
    </div>
  );
};

export default MatrimonyListUser;
