const MatrimonyTermsOfUser = () => {
  return (
    <>
      <p className="py-4 text-black">
      Greetings and welcome to Thandh Bendh Kodagu Heggade Matrimony which
includes its agents, affiliates and other representatives. You accept and agree to be
governed by the following terms and conditions by accessing and using this website.
Kindly carefully read these terms. You are not permitted to use the Matrimony
Website if you disagree with any portion of these Terms.
      </p>
      <p className="py-4 text-black">
        <h1 className="font-bold">Criteria</h1>
        <li>
        Creation of a account and registration are required in order to use some of the
website&#39;s features. Kodagu Heggade matrimony (Thandh Bendh) shall act on the
basis of the information you submit during the registration procedure is true, full,
correct and current even if the information is provided during the registration by
your family, friends, relatives on your behalf under your express consent. . Do
not disclose your password to anyone else (we will never ask you for your
password by telephone, live chat or email).
          
        </li>
        <li>
        The minimum age for registering in Kodagu Heggade matrimony (Thandh
          Bendh) is 18 for Women and 21 years Male.
        </li>
        
        
        <li>
          The Kodagu Heggade Matrimony (Thandh Bendh) website is designed
          exclusively to facilitate personal advertisements for lawful marriage alliances
          between individuals eligible to marry under applicable laws. Membership in the
          Service is void where prohibited by law.
        </li>
        

      </p>
      

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">REGISTRATION.</h1>
        <li>
        You are expected to complete the registration process with integrity by providing
accurate personal information. Please understand that the effectiveness of the
matchmaking process relies on you submitting true, accurate, current, and
complete information. You acknowledge that you are solely responsible for the
information provided in this application form. Kodagu Heggade Matrimony
(Thandh Bendh), its officers, or affiliates will not be liable for any actions taken
by others based on the information you provide. You agree to indemnify Kodagu
Heggade Matrimony (Thandh Bendh) against any claims or legal actions that
may arise due to the information you submit. Additionally, if Kodagu Heggade
Matrimony (Thandh Bendh) requests further details to better serve you, you
agree to provide them.
          
        </li>
        <li>
        If at any point of time Kodagu Heggade Matrimony (Thandh Bendh) comes to
know or has reasons to believe that any information provided by you for
registration or otherwise is found to be untrue, inaccurate, or incomplete then
Kodagu Heggade Matrimony (Thandh Bendh) shall have full right to suspend or
terminate your membership and forfeit the amount paid by you towards
membership fee and refuse to service you thereafter.  
        </li>
        
        
        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) also reserves the right to block the
registration of your profile on Website, if any, in the case of your contact
details/links being entered in irrelevant fields or if there are errors in any data
entered by the Kodagu Heggade Matrimony (Thandh Bendh) members in their
profile.
        </li>

        <li>

        Registration of duplicate profiles of the same person is not allowed in Kodagu
Heggade Matrimony (Thandh Bendh)Website. Kodagu Heggade Matrimony
(Thandh Bendh)shall have full right to suspend or terminate (without any notice)
such duplicate profile.
        </li>
        

      </p>
      </p>


      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ROLE AND RESPONSIBILITY OF KODAGU HEGGADE MATRIMONY
        (THANDH BENDH)</h1>
        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) reproduces your details once you
register on our website on &quot;as is as available&quot; basis and also share your profile
with other registered Kodagu Heggade Matrimony (Thandh Bendh) members
within website(s).
          
        </li>
        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) obligation is only to provide an
interface to its registered members to search their prospect themselves without
any assistance. 
        </li>
        
        
        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) cannot guarantee the complete
protection of user data while it is in transit, or prevent any tampering of the data
by a third party with malicious intent before the data reaches the Kodagu
Heggade Matrimony (Thandh Bendh) servers.
        </li>

        <li>

        When using the Website or its services, Kodagu Heggade Matrimony (Thandh
Bendh) and its affiliates will never be responsible for any indirect, incidental,
consequential, or punitive damages.
        </li>

        <li>
        Multiple profiles of the same person are not allowed on our Site. Kodagu
Heggade Matrimony (Thandh Bendh) reserves the right to deactivate all multiple
profiles without any refund of subscription fees.
        </li>
        

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ROLE AND RESPONSIBILITY OF KODAGU HEGGADE MATRIMONY
        (THANDH BENDH) MEMBER</h1>
        <li>
        You are responsible for all activities that occur under your account and for
keeping your login details confidential. You agree to notify us immediately if
there is any unauthorized use of your account. You must not transfer or share
your account with anyone else.
          
        </li>
        <li>
        You further affirm that you will not use this site for casual dating or any other kind
of commercial use, and that you plan to look for a life companion.
        </li>
        
        
        <li>
        You commit to using the service in accordance with all applicable laws and to all
        of the terms and conditions of this Agreement.
        </li>

        <li>

        To get better search results, Kodagu Heggade Matrimony (Thandh
Bendh)Members are expected to provide latest photograph which should not be
more than 3 (three) months old. Providing old photographs/ photographs of
others, inaccurate / false information shall be treated as violation of terms and
conditions and Kodagu Heggade Matrimony (Thandh Bendh)shall retain their
right under clause 2 (b) of this terms and conditions.
        </li>

        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) reserves the right to immediately
terminate your membership and/or your right to use the Service without providing
you with a refund for any unused subscription fees if at any point it determines
(in its sole discretion) or has any reason to believe that you are not eligible to
become a member, that you have made any misrepresentation, or that you have
violated any provisions of the Terms and Conditions and applicable laws.
        </li>
        

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">COPYRIGHT</h1>
        <li>
        All contents included on this site, such as text, graphics, button icons, images,
audio clips, digital downloads, data compilations, and software, is the property of
Kodagu Heggade Matrimony (Thandh Bendh)or its content suppliers and
protected by Indian and international copyright laws. The compilation of all
contents on this site is the exclusive property of Kodagu Heggade Matrimony
(Thandh Bendh)and protected by Indian and international copyright laws. All
software used on this site is the property of Kodagu Heggade Matrimony
(Thandh Bendh) or its software suppliers and protected by Indian and
international copyright laws.
          
        </li>
        <li>
        The content in Kodagu Heggade Matrimony (Thandh Bendh) website is intended
solely for private, non-commercial use. You are not allowed to copy, distribute, or
utilize any portion of the content without the website&#39;s prior written consent.
        </li>
        
        
       
        

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">MEMBERSHIP</h1>
        <li>
        Only Kodagu Heggade community members are eligible for Kodagu Heggade
Matrimony, also known as Thanh Bendh. By confirming your membership on the
matrimonial website, you are certified to your membership in this community.
          
        </li>
        <li>
        You can cancel your membership at any moment, for any reasons, by sending
an email to Kodagu Heggade Matrimony (Thandh Bendh) or by removing your
profile from the website. You will not be eligible for a refund of any unused
subscription costs that you may have paid in the event that you cancel your
membership. Neither the equipment or programming used in the Service nor the
behavior of any user or Member of the kodaguheggade.com/matrimony Service,
whether online or offline, shall be held accountable for any incorrect or
inaccurate Content posted on the Site or in connection with the kodaguheggade.com/matrimony Service. This includes users accessing the Site,
Members, and any equipment or programming used in the Service. Any error,
omission, interruption, deletion, malfunction, delay in operation or transmission,
communications line failure, theft or destruction, or unauthorized access to or
alteration of user and/or Member communications are not covered by Thandh
Bendh&#39;s liability policy. Thandh Bendh disclaims all liability for issues or technical
malfunctions with any phone networks or lines, computer online systems,
servers or providers, computer hardware, software, email malfunctions, or
interruptions due to traffic congestion on the Internet, at any website, or any
combination of these. This includes harm or injury to users, Members, or third
parties, as well as damage to any other person&#39;s computer resulting from using
or downloading materials in connection with the Thandh Bendh website and/or
the kodaguheggade.com Service. Any loss or injury to any person resulting from
using the Site, the Service, and/or any Content placed on the
kodaguheggade.com/matrimony website or transmitted to
kodaguheggade.com/matrimony Members shall not be held against
kodaguheggade.com. The sharing of a profile via
kodaguheggade.com/matrimony Must not be interpreted as an offer or advice
from Kodaguheggade.com/matrimony in any form. Marriage at
Kodaguheggade.com shall not be held accountable for any harm or loss that
results from relationships formed as a result of using kodaguheggade.com for
any individual. Kodaguheggade.com/matrimony specifically disclaims any
guarantee of fitness for a particular purpose or non-infringement. The Site and
the Service are offered &quot;AS-IS AVALIABLE BASIS&quot;. The use of the site and/or
the kodaguheggade.com/matrimony service does not guarantee any specific
results, and kodaguheggade.com/matrimony makes no such promises.
        </li>

        <li>
        For whatever reason, including but not limited to violating the terms of use, using
the service for profit, or engaging in prohibited or inappropriate communication or
activity, Kodagu Heggade Matrimony (Thandh Bendh) may terminate your
access to the Site and/or your membership. Kodagu Heggade Matrimony
(Thandh Bendh) will notify you via the email address you provided in your
membership application or any other email address you may subsequently
provide to Kodagu Heggade Matrimony (Thandh Bendh). If you violate any of the
terms of this agreement and Kodagu Heggade Matrimony (Thandh Bendh)
terminates your membership, you will not be eligible for a refund of any
subscription payments you may have paid. we will immediately deactivate your
Account and bar any further access to the Service and we will delete your Profile
and may also delete Your Contents.
        </li>
        
        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) reserve the right to periodically
revise the Membership Fees. These changes may be made at our discretion and
will be effective as soon as they are published or communicated.
        </li>
        
        
       
        

      </p>
      </p>


      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ROLE AND RESPONSIBILITY OF KODAGU HEGGADE MATRIMONY
        (THANDH BENDH) MEMBER</h1>
        <li>
        You are responsible for all activities that occur under your account and for
keeping your login details confidential. You agree to notify us immediately if
there is any unauthorized use of your account. You must not transfer or share
your account with anyone else.
          
        </li>
        <li>
        You further affirm that you will not use this site for casual dating or any other kind
of commercial use, and that you plan to look for a life companion.
        </li>
        
        

        

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">REFUND OF FEE</h1>
        <li>
        If you choose to terminate your membership, the MEMBERSHIP FEES ARE
NOT REFUNDABLE under any circumstances.
          
        </li>
        <li>
        Your membership for Kodagu Heggade Matrimony (Thandh Bendh)services is
for your sole, personal use. You may not authorize others to use your
membership and you may not assign or transfer your account to any other
person or entity.
        </li>
        
        

        

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">Complaints</h1>
        <li>
        If you have any concerns regarding content posted by users on any of the Sites or
any aspect of the Service, please reach out to us through the contact form available
on the &#39;Contact Us&#39; page of the relevant Site or email our Support Care Team at
kodaguheggade@gmail.com
          
        </li>
         

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">INDEMNITY</h1>
        <li>
        By using our Kodagu Heggade Matrimony (Thandh Bendh)services you agree to
defend, indemnify, and hold harmless Kodagu Heggade Matrimony (Thandh
Bendh), its subsidiaries, affiliates, Directors, officers, agents, and other partners
and employees, fully indemnified and harmless from any loss, damage, liability,
claim, or demand, including reasonable attorney&#39;s fees, made by any person
through improper use of the service provided by Kodagu Heggade Matrimony
(Thandh Bendh). This defence and indemnification obligation will survive in
perpetuity.
          
        </li> 

        <li>
        You shall be solely and exclusively liable for any breach of any country specific
rules and regulations or general code of conduct and Kodagu heggade
Matrimony cannot be held responsible for the same.
        </li>
         

      </p>
      </p>


      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ONLINE CONDUCT</h1>
        <li>
        You are responsible for the content and information [including profile and
photograph] you post or transmit using Kodagu Heggade Matrimony (Thandh
Bendh) services. You will not post or transmit any content or information [in
whatever form they may be contained] that is defamatory, blasphemous,
abusive, obscene, sexually oriented, profane, intimidating, illegal or in violation of
the rights of any person, including intellectual property rights. No content or
information shall contain details or particulars of any of Kodagu Heggade
Matrimony (Thandh Bendh) competitors, including their contact details. You will
not use either your online or offline membership as a platform for any kind of
promotional campaign, solicitation, advertising or dealing in any products or
services or transmit any chain letters or junk mails.
          
        </li> 

        <li>
        Mobile number verification is mandatory to contact other members. All profiles
are reachable through mobile. Profiles who have not verified their mobile
numbers will not be part of Kodagu Heggade Matrimony (Thandh Bendh) site
any more.
        </li>

        <li>
        Your membership will automatically be suspended if you send &quot;abusive, obscene
        or sexually oriented&quot; message/s to other members.
        </li>
         

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">GENERAL PROVISIONS</h1>
        <li>
        Site Policies, Modification and Severability Please review our other policies,
such as our pricing policy, posted on this site. We reserve the right to make
changes to our site, policies, and these Terms and Conditions of Use at any
time. If any of these conditions shall be deemed invalid, void, or for any reason
unenforceable, that condition shall be deemed severable and shall not affect the
validity and enforceability of any remaining condition.
        </li> 

        <li>
        If both parties have a shared interest, the member&#39;s profile contact details will
only be then displayed. This implies that before exchanging contact information,
both parties must show interest in one another.
        </li>

        <li>
        Kodagu Heggade Matrimony (Thandh Bendh) doesn&#39;t check any bride or
groom&#39;s history or does a background verification. It is entirely the users&#39;
responsibility to investigate and confirm any possible matches on their own.
        </li>
         
         <li>
         Kodagu Heggade Matrimony (Thandh Bendh) are committed to protecting the
privacy of children. You should be aware that this Site is not intended or
designed to attract children under the age of 21. We do not collect personally
identifiable information from any person we actually know is a child under the
age of 21.
         </li>

         <li>
         Communication between Users on the Sites is an integral part of the Service.
We do not vet, and are not responsible for, any information which is Posted on
the Sites. All Content on the Sites is viewed and used by you at your own risk
and we do not guarantee the accuracy or reliability of any of this information. The
views expressed are those of the individual contributors and not necessarily
those of kodaguheggade.com/matriony &amp; its allies. Please remember that with
free speech comes responsibility.
         </li>

         <li>

         If Kodagu Heggade Matrimony (Thandh Bendh) member violate any of these
Terms and Conditions, your permission to use the Content automatically
terminates and you must immediately destroy any copies you have made of any
portion of the Content.
         </li>

         <li>
         The Sites are accessed via the World Wide Web, which is independent of us.
Your use of the World Wide Web is solely at your own risk and subject to all
applicable national and international laws and regulations. We do not represent
that the Sites are appropriate or available for use in any jurisdiction other than
the INDIA. We shall have no responsibility for any information or service
obtained by you on the World Wide Web.
         </li>

      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">APPLICABLE LAW</h1>
        <li>
        According to the Indian Penal Code, creating and managing fake profiles is
illegal and constitutes cybercrime. Users are legally liable for the consequences;
Kodaguheggade.com will not be held accountable and will assist the authorities
in apprehending offenders.
          
        </li>
        <li>
        We will have no liability arising from any link being placed on the Sites to another
website which we are not responsible for.
        </li>

        <li>
        In certain circumstances, we may have a legal obligation to disclose your
contact/registration details and other material. Should we do this you agree to
take no action against kodaguheggade.com
        </li>

        <li>
        If there is any dispute about or involving the Site and/or the Service, by using the
Site, you unconditionally agree that all such disputes and/or differences will be
governed by the laws of India and shall be subject to the exclusive jurisdiction of
the Competent Courts in Virajpet, Kodagu, India only.
        </li>
        
        

        

      </p>
      </p>





      

      




    </>
  );
};

export default MatrimonyTermsOfUser;
