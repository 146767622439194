// import aboutBgImage from "./assets/about_bg.png"
import AboutMainSection from "./AboutMainSection";
import Carousel from "./Carousel";
import Marquee from "./Marquee";
import Testimonials from "./Testimonials";
import RegisterForBusiness from "./RegisterForBusiness";



const About = () => {
  



  return (
    <>
    
   
    <div className="flex justify-center p-[4%] bg-slate-50 shadow-xl">
        <div className="w-[80%] card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body bg-gradient-to-r  from-main-color to-to-color">
            <h2 className="card-title text-5xl">About Us</h2>
            <p className="font-medium text-3xl">
            The Kodagu Heggade is one of the principal Kodagu language speaking communities in Kodagu. Presently, the Kodagu language is popularly known as Kodava language. According to the 19th-century Kittel dictionary, 'Heggade' means "hiridaada gade" (ಹಿರಿದಾದ ಗಡೆ), which translates to Senior leader. "Hiridada" means big or senior, and "Gade" means leader (ಮುಖ್ಯಸ್ಥ). In old Kannada, Heggade was pronounced as Peggade (ಪಿರಿದಾದ ಗಡೆ), and the term Heggade is synonymous with Peggade.

            </p> <hr></hr>

            <p className="font-medium text-3xl">
            This community, along with 21 other Kodagu-speaking inhabitant communities, shares a common language, tradition, rituals, attire, and festivals in the Kodagu region since time immemorial. However, some historical records suggest that traces of the Heggades can be found in the Chengalva and Kongalva principalities, which existed approximately 1000 years ago.
            </p> <hr></hr>

            <p className="font-medium text-3xl">
            It is generally believed that the Heggades were a tax-collecting class (later caste) until the advent of Haider Ali in the Kodagu province in the late 18th century. According to late 18th-century European writings, Haider Ali replaced the revenue-collecting communities in Kodagu. It is possible that the Heggades lost their tax collection responsibilities and remained agriculturists and warriors since they have held Jamma lands for centuries. In the 25th Hukumnama of 1811, Kodagu King Lingaraj Wodeyar exempted the Heggades, Amma Kodavas (vegetarian agriculturists), Mukkatis (temple servants and agriculturists), and Kodavas (agriculturists) from paying caste taxes to the principality. Kodagu Heggades were one of the privileged communities during the reign of the Lingayat Kings of Coorg. However, they lost their significance soon after the British annexation of Kodagu in 1834. Some Heggades served in the British administration as officials, though not in higher positions.

            </p> <hr></hr>

            <p className="font-medium text-3xl">
            The prosperity of the community has not changed drastically even after independence. The Heggades were included in the 3A OBC category along with other forward communities until the 1990s. They were listed in the 2A OBC category after the intervention of community leaders. The community's fortunes have improved somewhat since globalization, with some youth securing jobs in private and corporate sectors rather than public establishments. However, the community is gradually rejuvenating and striving for greater prosperity at present.
            </p>

            
           
          </div>
        </div>
      </div>
    <Testimonials />
    </>

  )
}

export default About


