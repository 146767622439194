import { DOMAIN_NAME } from "../../Constant";
import { Link } from "react-router-dom";

const BusinessListCard = ({ name, shopImage, id }) => {
  return (
    <div className="card w-96 shadow-lg m-1 mb-4 bg-slate-50">
      <figure className="px-10 pt-10">
        <img
          src={DOMAIN_NAME + shopImage}
          alt="Shoes"
          className="rounded-xl w-full h-[200] object-cover"
        />
      </figure>
      <div className="card-body items-center text-center">
        <h2 className="card-title">{name}</h2>
        <div className="card-actions">
          <Link to={'/business/profile/' + id}>
            <button className="btn bg-main-color">View Business</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BusinessListCard;
