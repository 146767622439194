import React from 'react'

const Loading = () => {
  return (
    <div className='flex justify-center items-center h-dvh'>
        <span className="loading loading-dots loading-lg"></span>
    </div>
   
  )
}

export default Loading


