import React from "react";
import BannerOneImage from "./assets/banner1.jpg";
import BannerTwoImage from "./assets/banner2.png";
import BannerThreeImage from "./assets/banner3.jpg";
import BannerFourImage from "./assets/banner4.jpg";
import BannerFiveImage from "./assets/banner5.jpg";
import BannerSixImage from "./assets/banner6.jpg";

const Testimonials = () => {
  return (
    <>
      <div className="bg-main-color p-[2%]">
        <h1 className="text-center font-light text-4xl m-2">Matrimony Service with Success Stories</h1>
        <div className="flex justify-center  ">
          <div className="carousel rounded-box md:w-[80%] sm:w-full">
            <div className="carousel-item m-2">
              <div className="max-w-sm">
                <div className="shadow-lg rounded overflow-hidden">
                  <img className="w-96 h-64 object-cover" src={BannerOneImage} />
                  {/* <div className="bg-white p-12">
                    <p className="font-light text-xl italic font-gray-800">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam.
                    </p>
                   
                    <p className="mt-1 font-semibold text-sm text-gray-900">
                      Couple name
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="carousel-item m-2">
              <div className="max-w-sm">
                <div className="shadow-lg rounded overflow-hidden">
                  <img className="w-96 h-64 object-cover" src={BannerTwoImage} />
                  {/* <div className="bg-white p-12"> */}
                    {/* <p className="font-light text-xl italic font-gray-800"> */}
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, */}
                      {/* sed do eiusmod tempor incididunt ut labore et dolore magna */}
                      {/* aliqua. Ut enim ad minim veniam. */}
                    {/* </p> */}
                   {/*  */}
                    {/* <p className="mt-1 font-semibold text-sm text-gray-900"> */}
                      {/* Couple name */}
                    {/* </p> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="carousel-item m-2">
              <div className="max-w-sm">
                <div className="shadow-lg rounded overflow-hidden">
                  <img className="w-96 h-64 object-cover" src={BannerFiveImage} />
                  {/* <div className="bg-white p-12"> */}
                    {/* <p className="font-light text-xl italic font-gray-800"> */}
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, */}
                      {/* sed do eiusmod tempor incididunt ut labore et dolore magna */}
                      {/* aliqua. Ut enim ad minim veniam. */}
                    {/* </p> */}
                   {/*  */}
                    {/* <p className="mt-1 font-semibold text-sm text-gray-900"> */}
                      {/* Couple name */}
                    {/* </p> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="carousel-item m-2">
              <div className="max-w-sm">
                <div className="shadow-lg rounded overflow-hidden">
                  <img className="w-96 h-64 object-cover" src={BannerSixImage} />
                  {/* <div className="bg-white p-12"> */}
                    {/* <p className="font-light text-xl italic font-gray-800"> */}
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, */}
                      {/* sed do eiusmod tempor incididunt ut labore et dolore magna */}
                      {/* aliqua. Ut enim ad minim veniam. */}
                    {/* </p> */}
                   {/*  */}
                    {/* <p className="mt-1 font-semibold text-sm text-gray-900"> */}
                      {/* Couple name */}
                    {/* </p> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
