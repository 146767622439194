import { useState, useEffect } from "react";

const Event = () => {
  // const [counter, setCounter] = useState(60);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter - 1);
  //   }, 1000);
  
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
  
  return (
    <div className="p-[2%] flex justify-evenly flex-wrap h-[70vh] items-center"> 

      <h1 className="font-extrabold text-2xl">No Events Yet.</h1>
      {/* <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>

      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div>
      <div className="card bg-base-100 w-96 shadow-xl m-4">
        <figure>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlZpVGoOekMHL3Z9sGBbcwYWzAcAavnqfXdbnrr5a_7c5ptukr9-2ftqXqaNy0kVc9qs&usqp=CAU"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            Event 1<div className="badge badge-outline">Type of event</div>
          </h2>
          <p>Explaination about the event</p>
          <div className="flex gap-5">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 15 }}></span>
              </span>
              days
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 10 }}></span>
              </span>
              hours
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": 24 }}></span>
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={{ "--value": counter }}></span>
              </span>
              sec
            </div>
          </div>

          

        
        </div>
      </div> */}
      
    </div>
  );
};

export default Event;
