import { useState } from "react";
import Testimonials from "./Testimonials";

const AboutMainSection = () => {
  return (
    <>
      <div className="flex justify-center flex-wrap p-[5%] bg-slate-50 items-center">
        <div className="card sm:w-4/5 md:w-96 m-2 w-4/5 h-56 bg-base-100 shadow-xl ">
          <div className="card-body">
            <h2 className="card-title">Our intention</h2>
            <p>
              From the early stages it has been our goal to help you connect
              with someone of your choice. For a long lasting relationship of
              your dreams. Our process is a fusion of old matchmaking principles
              with integration of modern technology.
            </p>
          </div>
        </div>
        <div className="card sm:w-4/5 md:w-96 m-2 w-4/5 h-56 bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Our Vision</h2>
            <p>
              To be the No.1 in our niche and introduce new features beneficial
              to members. To continue to grow with a loyal and increasing
              customer network across the globe.
            </p>
          </div>
        </div>
        <div className="card sm:w-4/5 md:w-96 m-2 w-4/5 h-56 bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Our Mission</h2>
            <p>
              To help you move closer to the desired outcome with convenience.
              To provide a safe progressive matchmaking platform. Offer new
              matchmaking features to meet member expectations. Continued growth
              in our membership base around the world.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-main-color w-full overflow-hidden">
        <div className={`container mx-auto`}>
          <div className={`w-full`}>
            <Testimonials />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMainSection;
