import React from "react";

const BusinessTermsOfUser = () => {
  return (
    <>
      <p className="py-4 text-black">
      Welcome to VYAPARA VYVAATU (Kodagu Heggade Business) (&quot;the Website&quot;). By
accessing or using the Website, you agree to comply with and be bound by the following
terms and conditions (&quot;Terms&quot;). Please read these Terms carefully before using the
Website. If you do not agree with these Terms, you must not use the Website.
      </p>
      
      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ACCEPTANCE OF TERMS</h1>
        <li>
        By accessing or using the Website, you accept and agree to be bound by these Terms.
If you are using the Website on behalf of an organization, you represent that you
have the authority to bind that organization to these Terms.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">PLATFORM SERVICES:</h1>
        <li>
        The Website offers a platform for businesses to be listed for promotional purposes,
enabling them to reach a wider audience. Businesses seeking to be listed must apply
by submitting accurate and up-to-date information via the provided submission
form. The Website retains the sole discretion to accept, reject, or remove any
business listing at any time, without prior notice.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ACCOUNT REGISTRATION</h1>
        <li>
        To access certain features of the Website, including business listings and
        promotional tools, you must register for an account.
          
        </li>
        <li>
        Registration requires providing accurate and complete information as prompted
by the registration form. You are responsible for maintaining the confidentiality
of your account credentials and for all activities that occur under your account.
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">PURCHASES AND PAYMENTS</h1>
        <li>
        All purchases made through the Website are subject to our payment terms.
          
        </li>
        <li>
        We reserve the right to change our prices and payment terms at any time
        without notice.
        </li>

        <li>
        You agree to provide accurate and complete payment information for all
        purchases made through the Website.
        </li>
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">ELIGIBILITY</h1>
        <li>
        Vyapara Vyvaat is a unique initiative that is exclusively available to members of the
Kodagu Heggade community. This specialized platform caters specifically to the
needs and interests of community members, offering tailored services and
opportunities that align with their distinct cultural and business practices. By
focusing solely on the Kodagu Heggade community, Vyapara Vyvaat ensures that its
offerings are deeply relevant and beneficial, fostering a stronger network and more
effective engagement among its members. You must be at least 18 years old to use
the Website.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">USE OF THE WEBSITE</h1>
        <li>
        User Accounts: You may be required to create an account to access certain
features of the Website. You are responsible for maintaining the confidentiality
of your account information and for all activities that occur under your account.
        </li>
        <li>
        Prohibited Conduct: You agree not to engage in any activity that:
i. Violates any applicable law or regulation;
ii. Infringes on the rights of any third party;
iii. Is fraudulent, misleading, or deceptive;
iv. Distributes viruses or harmful software;
v. Interferes with the operation of the Website.
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">PROHIBITED CONDUCT</h1>
        <li>
        You agree not to use the Website for any unlawful, harmful, or objectionable
activities, including, but not limited to, the distribution of harmful or illegal content,
spam, or other forms of unsolicited communication. Additionally, you agree to use
the Service in compliance with all applicable laws and these Terms and Conditions.
Vyapara Vyvaat reserves the right, at its sole discretion, to terminate your
membership and/or access to the Service without refund if it determines, or has
reason to believe, that you are ineligible for membership, have made any
misrepresentation, or have violated any provisions of these Terms.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">USE OF CONTENT</h1>
        <li>
        The content provided on this website, including text, images, graphics, and other
materials, is intended solely for informational and educational purposes. All content
is the property of VYAPARA VYVAATU (Kodagu Heggade Business) or its content
suppliers and is protected by copyright, trademark, and other intellectual property
laws. Users are granted a limited, non-exclusive, non-transferable license to access
and view the content for personal, non-commercial use. Any unauthorized
reproduction, distribution, modification, or transmission of the content, whether in

whole or in part, without prior written permission from [Your Company Name] is
strictly prohibited.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">INDEMNIFICATION</h1>
        <li>
        You agree to indemnify, defend, and hold harmless VYAPARA VYVAATU and its
affiliates, officers, directors, employees, and agents from and against any claims,
liabilities, damages, losses, and expenses, including legal fees, arising out of or in any
way connected with your use of the Website or violation of these Terms.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">TERMINATION</h1>
        <li>
        We reserve the right to terminate or suspend your access to the Website, without
notice, for conduct that we believe violates these Terms or is harmful to other users
of the Website, us, or third parties, or for any other reason.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">GOVERNING LAW</h1>
        <li>
        These Terms shall be governed by and construed in accordance with the laws of
India
        </li>
        <li>
        Any disputes arising under these Terms shall be subject to the exclusive
        jurisdiction of the courts located in Virajpet, Kodagu.
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">CHANGES TO TERMS</h1>
        <li>
        We reserve the right to modify these Terms at any time without prior notice.
        </li>
        <li>
        Your continued use of the Website after any changes to the Terms indicates your
        acceptance of the revised Terms.
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">SEVERABILITY</h1>
        <li>
        If any provision of these Terms is found to be invalid or unenforceable, the
        remaining provisions shall remain in full force and effect.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">WORLD WIDE WEB</h1>
        <li>
        The Website is accessed via the World Wide Web, which is independent of Vyapara
Vyvaat. Your use of the World Wide Web is at your own risk and subject to all
applicable national and international laws and regulations. We do not represent that
the Website is appropriate or available for use outside of India and assume no
responsibility for any information or services obtained through the World Wide
Web.
          
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">DISCLAIMERS</h1>
        <li>
        No Warranties: The Website is provided &quot;as is&quot; and &quot;as available&quot; without any
warranties of any kind, express or implied. VYAPARA VYVAATU does not warrant
that the Website will be uninterrupted, error-free, or secure.
Limitation of Liability: To the fullest extent permitted by law, VYAPARA VYVAATU
shall not be liable for any damages arising out of or related to your use of the
Website, including but not limited to direct, indirect, incidental, punitive, and
consequential damages.
        </li>
        <li>
        Limitation of Liability: To the fullest extent permitted by law, VYAPARA VYVAATU
shall not be liable for any damages arising out of or related to your use of the
Website, including but not limited to direct, indirect, incidental, punitive, and
consequential damages.
        </li>
      
      </p>
      </p>

      <p>
      <p className="py-4 text-black">
        <h1 className="font-bold">CONTACT INFORMATION</h1>
        <li>
        If you have any query regarding the content posted by any user or any aspect of the
Service, please contact us via the contact form provided on the &#39;Contact Us&#39; page of
the Website or email our Support Care Team at kodaguheggade@gmail.com.
          
        </li>
      
      </p>
      </p>
      
    </>
  );
};

export default BusinessTermsOfUser;
