import AboutMainSection from "./AboutMainSection";
import Carousel from "./Carousel";
import Marquee from "./Marquee";
import Testimonials from "./Testimonials";
import RegisterForBusiness from "./RegisterForBusiness";



const Home = () => {
  



  return (
    <>
    <div className="sm:h-[5vh] md:h-[5vh] bg-gradient-to-r marquee from-main-color to-to-color flex justify-center items-center">
    <div className="relative flex overflow-x-hidden">
        <div className=" animate-marquee whitespace-nowrap">
          <span className="text-4xl mx-4">Events Coming Soon!</span>
          <span className="text-4xl mx-4">Events Coming Soon!</span>
          <span className="text-4xl mx-4">Events Coming Soon!</span>
          <span className="text-4xl mx-4">Events Coming Soon!</span>
          <span className="text-4xl mx-4">Events Coming Soon!</span>
          <span className="text-4xl mx-4"></span>          
        </div>

      </div>
    </div>
    <Carousel />
    <RegisterForBusiness />
    <Testimonials />
    </>

  )
}

export default Home