import React, { useEffect, useState } from "react";
import { DOMAIN_NAME } from "../../Constant";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SIGN_UP, DOMAIN_NAME } from "../../Constant";

const Signup = () => {
  
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("");
  

  const navigate = useNavigate();
  console.log('hels')
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const signUpData = {
      email: email,
      mobile_number: phoneNumber,
      is_matrimony_user: true
    };

    try {
      const response = await fetch(DOMAIN_NAME + SIGN_UP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signUpData),
      });

      const responseData = await response.json()
      console.log(response,responseData)
      if (response.ok) {
        setStatus(`${responseData?.message} please login`);
        setEmail("");
        setPhoneNumber("");

      }

      if (!response.ok) {
        // setStatus(`${responseData responseData?.responseData?.email responseData?.responseData?.mobile_number}` )
        setStatus(`${responseData?.message} (${responseData?.data?.email} ${responseData?.data?.mobile_number[0]})`);        
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="h-screen flex bg-gradient-to-r from-red-300 to-red-100">
        <div className=' h-full w-full flex justify-center items-center bg-cover border-r-2'>
        
          <div className="w-4/5">
            <h1 className="p-2 pl-0 font-bold border-dashe lg:text-6xl text-center m-5">
                Thandh Bendh
            </h1>
            <div className={`bg-main-color p-4 shadow-2xl`}>
              
              <form onSubmit={handleSubmit}>
                <p>{status}</p>
                <input
                  type="text"
                  placeholder="Email"
                  className="input input-bordered w-full  block p-2 mb-2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="input input-bordered w-full  block p-2 mb-2"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />

              
                  <button className="btn hover:btn-outline w-full p-2 mb-2">
                    Matrimony Signup
                  </button>
                
              </form>
              <hr className=" block w-full"></hr>
              <p className="text-center p-2">Already have account?</p>
              <Link to="/matrimony/login">
                <button className="btn hover:btn-outline w-full p-2">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className=' h-full w-full flex justify-center items-center bg-cover'>
        
        <div className="w-4/5">
          <h1 className="p-2 pl-0 font-bold border-dashe lg:text-6xl text-center m-5">
              Vypara
          </h1>
          <div className={`bg-main-color p-4 shadow-2xl`}>
            
            <form>
              <input
                type="text"
                placeholder="Email"
                className="input input-bordered w-full  block p-2 mb-2"
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="input input-bordered w-full  block p-2 mb-2"
              />

              <Link>
                <button className="btn hover:btn-outline w-full p-2 mb-2">
                  Business Signup
                </button>
              </Link>
            </form>
            <hr className=" block w-full"></hr>
            <p className="text-center p-2">Already have account?</p>
            <Link>
              <button className="btn hover:btn-outline w-full p-2">
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
      </div>

     
    </>
  );
};

export default Signup;
