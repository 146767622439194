import useBusinessDetails from "../../utils/useBusinessDetails";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import  LoadingDetail  from "../UX/LoadingDetail";
import BusinessDetailCard from "./BusinessDetailCard";

const BusinessDetail = () => {
  const { id } = useParams();

  const [businessDetail] = useBusinessDetails(id);

  console.log(businessDetail, typeof businessDetail);
  return businessDetail.length == 0 ? (
    <LoadingDetail />
  ) : (
    <div className="lg:mt-20 sm:mt-8 mt-8 flex items-center justify-center">
      <div className="w-2/3">
        <BusinessDetailCard details={businessDetail}/>
      </div>
    </div>
  );
};

export default BusinessDetail;
